import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SliderIndex from "../components/slider-index"
import ReactModal from 'react-modal'

const jQuery = require("jquery");
const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    content: {
        position: "relative",
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        maxWidth: "560px",
        margin: "140px auto 0",
        padding: 0,
        border: 0,
        textAlign: "center"
    },
};

const axios = require("axios");

const IndexPage = () => {
    const [modalOpen, setModalOpen] = useState(true);
    const modalCloseTimeout = 300;
    const closeModal = () => {
        setModalOpen(false);
    };
    const inscribete = () => {
        setModalOpen(false);
    }
    const [email, setEmail] = useState("")


    return (
        <Layout>
            <SEO title="Home"/>
            <SliderIndex/>
            {/* ============================================================== */}
            {/* Feature 3  */}
            {/* ============================================================== */}
            <div className=" bg-white spacer feature3" id="quienes">
                <div className="container">
                    {/* Row  */}
                    <div className="row justify-content-center">
                        <div className="col-md-7 text-center">
                            <h2 className="title">¿Quiénes somos?</h2>
                        </div>
                    </div>
                    {/* Row  */}
                    <div className="row m-t-40">
                        <div className="col-md-12">
                            <div className="card" data-aos="fade-right" data-aos-duration={1200}>
                                <div className="card-body d-flex">
                                    <div className="row d-flex align-items-center">
                                        {/*<div className="col-md-2">*/}
                                        {/*<div className="icon-space align-self-center"><i*/}
                                        {/*className="icon-Letter-Open display-2 text-inverse"/></div>*/}
                                        {/*</div>*/}
                                        <div className="col-md-12">
                                            <div className="align-self-center">
                                                <p className="m-t-20">La Fundación Pedro Pérez Garrido (FPPG) fue
                                                    fundada por Manuel Salvador Pérez Alavez “Manolo” en honor a su
                                                    difunto abuelo, Don Pedro Pérez Garrido, uno de los más grandes
                                                    luchadores sociales de la historia de Quintana Roo, quien, junto a
                                                    otros prohombres del estado, como su cuñado, Don Arturo Namur, fue
                                                    participe para evitar que el territorio de Quintana Roo sea
                                                    desmembrado entre Campeche y Yucatán.</p>
                                                <p className="m-t-20">La FPPG es una organización comprometida a
                                                    impulsar el bienestar y desarrollo económico del sur de Quintana
                                                    Roo, a través de una obra social de alto impacto, así como la
                                                    definición de estrategias de desarrollo económico relacionadas al
                                                    sector turístico, generación de cadenas productivas y mejores
                                                    prácticas para la posesión de la tierra.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============================================================== */}
            {/* End Feature 3  */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* Feature 46  */}
            {/* ============================================================== */}
            <div className="spacer feature46 bg-light" style={{backgroundImage: 'url(images/features/img5.png)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <h2 className="title">Nuestras líneas de trabajo y programas</h2>
                            {/* <h6 className="subtitle m-b-40 m-t-20">You can relay on our amazing features list and also our customer services will be great experience for you without doubt and in no-time</h6> */}
                            <div className="card card-shadow " data-aos="fade-right" data-aos-duration={1200}>
                                <div className="card-body p-30">
                                    {/* <h6 className="font-medium">La Fundación Pérez Garrido se dedica a la realización de estudios e investigaciones remuneradas sobre temas de gran relevancia económico-social en el Sur del Estado de Quintana Roo, siguiendo las metodologías más rigurosas y de mayor jerarquía técnica aplicables a las respectivas disciplinas y campos. Esto a su vez le permiten enriquecer su patrimonio para el mejor cumplimiento de su objetivo por dignificar la vida de sus habitantes de manera sostenida.</h6> */}
                                    <p className="m-t-20">La Fundación Pedro Pérez Garrido (FPPG) se dedica a la
                                        realización de estudios e investigaciones sobre temas de alta relevancia
                                        económico-social para el Sur del Estado de Quintana Roo. La remuneración
                                        recibida por una parte de los estudios realizados le permiten a la FPPG
                                        incrementar su patrimonio, que se ejerce al 100% en dignificar la vida de los
                                        habitantes de Othón P. Blanco “Chetumal”.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============================================================== */}
            {/* End Feature 46  */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* Testimonial 3  */}
            {/* ============================================================== */}
            <div className="testimonial3 spacer" id="sector-turistico">
                <div className="container">
                    {/* <div className="row justify-content-center">
          <div className="col-md-7 text-center">
            <h2 className="title">Nuestras líneas de trabajo y programas</h2>
            <h6 className="subtitle">La Fundación Pérez Garrido se dedica a la realización de estudios e investigaciones remuneradas sobre temas de gran relevancia económico-social en el Sur del Estado de Quintana Roo, siguiendo las metodologías más rigurosas y de mayor jerarquía técnica aplicables a las respectivas disciplinas y campos. Esto a su vez le permiten enriquecer su patrimonio para el mejor cumplimiento de su objetivo por dignificar la vida de sus habitantes de manera sostenida.</h6>
          </div>
        </div> */}
                    <div className="row d-flex align-items-center mb-5">
                        <div className="col-lg-5" data-aos="fade-right" data-aos-duration={1200}>
                            <h3 className="title m-b-30">Estudios del turismo</h3>
                            <div className="d-flex no-block flex-column">
                                <p className="m-b-0">En México y otros países, el turismo constituye una actividad
                                    económica de gran importancia por las divisas que aporta, así como la considerable
                                    cantidad de empleos directos e indirectos que genera. Por ello la necesidad de
                                    profundizar en el estudio de sus dinámicas y mejoras, con especial atención a la
                                    competitividad y calidad de vida de los habitantes de polos turísticos.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 mt-4 mt-lg-0" data-aos="fade-left"
                             data-aos-duration={1200}>
                            <img src="images/testi/1.jpg" alt="wrapkit"
                                 className="img-fluid"/>
                        </div>
                    </div>
                    <div id="investigacion-innovacion" className="row d-flex align-items-center mb-5">
                        <div className="col-lg-5" data-aos="fade-right" data-aos-duration={1200}>
                            <img src="images/testi/4.jpg" alt="wrapkit"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-lg-5 offset-lg-2" data-aos="fade-left" data-aos-duration={1200}>
                            <h3 className="title m-b-30">Desarrollo regional</h3>
                            <div className="d-flex no-block flex-column">
                                <p className="m-b-0">Elaboración de análisis orientado a enriquecer el conocimiento y
                                    comprensión del desarrollo regional, conduciendo al mejoramiento de las
                                    condiciones de vida de la población, a través de un mayor efecto multiplicador del
                                    dinero ingresado por el turismo.</p>
                            </div>
                        </div>
                    </div>
                    <div id="posesion-tierras" className="row d-flex align-items-center mb-5">
                        <div className="col-lg-5" data-aos="fade-right" data-aos-duration={1200}>
                            <h3 className="title m-b-30">Adopción de mejores prácticas sobre posesión de la tierra</h3>
                            <div className="d-flex no-block flex-column">
                                <p>Introducir las mejores prácticas y marcos legales para atacar los
                                    desafíos relacionados con la posesión de la tierra y derechos de propiedad en la
                                    región, tales como el avasallamiento, ocupación ilegal, degradación del medio
                                    ambiente, seguridad jurídica, entre otros.</p>
                                <p className="m-b-0">Consideramos que el inventario de tierra estatal y municipal, así
                                    como la liberación de la enajenación mal habida de una gran parte de este
                                    inventario, que diferentes personajes han llevado a cabo en perjuicio del pueblo de
                                    Quintana Roo, nos brindará la gasolina necesaria para impulsar el desarrollo del sur
                                    del estado, y recuperar su dignificación.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 mt-4 mt-lg-0" data-aos="fade-left"
                             data-aos-duration={1200}>
                            <img src="images/testi/3.jpg" alt="wrapkit"
                                 className="img-fluid"/>
                        </div>
                    </div>
                    <div id="cadenas-valor" className="row d-flex align-items-center mb-5">
                        <div className="col-lg-5" data-aos="fade-right" data-aos-duration={1200}>
                            <img src="images/testi/2.jpg" alt="wrapkit"
                                 className="img-fluid"/>
                        </div>
                        <div className="col-lg-5 offset-lg-2" data-aos="fade-left" data-aos-duration={1200}>
                            <h3 className="title m-b-30">Creación de cadenas de valor</h3>
                            <div className="d-flex no-block flex-column">
                                <p className="m-b-0">Perseguimos la generación de productos endémicos de Chetumal, para
                                    distribuirse a los turistas del norte del estado: buscamos crear la marca
                                    “Chetumal”, para generar empleos, y que la gente de nuestra querida ciudad no tenga
                                    que migrar, y dejar atrás su familia y tradiciones, por una mejor vida, que bien
                                    puede tener en Chetumal.</p>
                            </div>
                        </div>
                    </div>
                    <div id="economicos-sociales" className="row d-flex align-items-center mb-5">
                        <div className="col-lg-5" data-aos="fade-right" data-aos-duration={1200}>
                            <h3 className="title m-b-30">Evaluación de proyectos</h3>
                            <div className="d-flex no-block flex-column">
                                <p className="m-b-0">Evaluación de proyectos de inversión y sociales, en apego a los
                                    principios y metodologías más innovadoras y aceptadas, para llegar a una eficiente y
                                    transparente asignación de recursos.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 mt-4 mt-lg-0" data-aos="fade-left"
                             data-aos-duration={1200}>
                            <img src="images/testi/5.jpg" alt="wrapkit"
                                 className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============================================================== */}
            {/* End Testimonial 3  */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* Feature 42  */}
            {/* ============================================================== */}
            <div className="spacer feature42" id="donativos" style={{backgroundImage: 'url(images/features/img3.png)'}}>
                <div className="container">
                    <div className="row justify-content-center wrap-feature42-box">
                        <div className="col-md-10 col-lg-7 text-center" data-aos="fade-up">
                            <h2 className="title text-white">Donativos y obra social</h2>
                            <p className="subtitle text-white m-b-20 font-bold">Brindamos apoyo a los grupos más vulnerables en la
                                Zona Sur de Quintana Roo, a través de apoyos en especie, impartición de programas de
                                educación y capacitación, así como llevamos a cabo la recaudación y canalización de
                                fondos a organizaciones locales con objetivos comunes a nuestra Fundación.</p> <a
                            href="#"
                            className="text-white display-4"
                            data-toggle="modal"
                            data-target="#exampleModal"><i
                            className="icon-Play-Music"/></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============================================================== */}
            {/* End Feature 42  */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* Call-to-Action 2  */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* End Call-to-Action 2  */}
            {/* ============================================================== */}
            {/* <ReactModal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal In Gatsby"
                style={modalStyles}
            >
                <div className="close p-2" onClick={closeModal}><i className="sl-icon-close"></i></div>
                <div className="p-5">

                    <h3>¿Quieres saber más sobre nosotros y nuestra labor?</h3>
                    <p>Regístrate para recibir nuestros correos electrónicos. Te compartiremos artículos inspiradores
                        sobre
                        nuestra misión, te mantendremos informados de las últimas noticias relevantes y te haremos saber
                        cómo puedes involucrarte.</p>
                    <div id="result"></div>
                    <form method="post" id="suscripcion-form">
                        <input name="email" id="email" onChange={event => setEmail(event.target.value)}
                               className="form-control"
                               type="text"/>
                        <br/>
                        <button id="btn-cont" className="mt-3 btn btn-outline-info btn-arrow"><span>Inscríbete <i
                            className="ti-arrow-right"></i></span></button>
                    </form>
                </div>
            </ReactModal> */}
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal In Gatsby"
                style={modalStyles}
            >
                <div className="close p-2" onClick={closeModal}><i className="sl-icon-close"></i></div>
                {/* <img src="images/contact/Folleto.jpeg" alt="wrapkit" className="img-fluid"/> */}
                <video width="520" height="420" controls className="img-fluid" autoplay>
                    <source src="images/fundacion.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </ReactModal>
        </Layout>
    )
}

export default IndexPage
