import PropTypes from "prop-types"
import React from "react"

import img4 from "../../static/images/img4.png"

const SliderIndex = ({siteTitle}) => (
    <div className="static-slider4">
        <div className="container">
            {/* Row  */}
            <div className="row">
                <div className="col-md-5 img-anim" data-aos="fade-up" data-aos-duration={2200}>
                    <img src={img4} alt="wrapkit" className="img-fluid"/>
                </div>
                {/* Column */}
                <div className="col-md-6 ml-auto align-self-center" data-aos="fade-right" data-aos-duration={1200}>
                    <h1 className="title text-center"><b className="font-bold">Por la dignidad del sur<span
                        className="text-info-gradiant typewrite" data-period={2000}
                        data-type="[ &quot;SEO Expert&quot;, &quot;HTML Expert&quot;, &quot;CSS3 Expert&quot;]"/></b>
                    </h1>
                    <h1 className="title"><b className="font-bold">Fundación Pedro Pérez Garrido<span
                        className="text-info-gradiant typewrite" data-period={2000}
                        data-type="[ &quot;SEO Expert&quot;, &quot;HTML Expert&quot;, &quot;CSS3 Expert&quot;]"/></b><br/>Trabajamos
                        para mejorar la calidad de vida de los chetumaleños, a través de propuestas de acción para
                        lograr un mayor empleo y bienestar, y la ejecución de acciones altruistas de gran impacto
                        social.</h1>
                    <div className="text-center"><a
                        className="btn btn-outline-info btn-md btn-arrow m-t-20 m-b-40 ml-auto mr-auto fundacion"
                        href="/quienFue"><span>¿Quién fue Pedro Pérez Garrido? <i
                        className="ti-arrow-right"/></span></a></div>
                </div>
                {/* Column */}
            </div>
        </div>
        <div className="bg-info">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 ml-auto info-box">
                        <div className="d-flex no-block">
                            <div className="display-4 text-white m-r-20">
                                {/* <i className="fa fa-play-circle" /> */}
                            </div>
                            <div className="align-self-center">
                                <h4 className="font-light m-b-0">
                                    {/*  texto en barra azul */}
                                    &nbsp;

                                </h4></div>
                            <div className="modal fade" id="static-slide3">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Watch video</h5>
                                            <button type="button" className="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div className="modal-body" id="yt-player">
                                            {/* <iframe width="100%" height={315} src="https://www.youtube.com/embed/DDwbjWCgxVM?" allowFullScreen /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

SliderIndex.propTypes = {
    siteTitle: PropTypes.string,
}

SliderIndex.defaultProps = {
    siteTitle: ``,
}

export default SliderIndex
